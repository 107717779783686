// Grey Palette
export const grey = {
  50: "#fcfcfc",
  100: "#f4f4f5",
  200: "#e4e4e7",
  300: "#d4d4d8",
  400: "#a1a1aa",
  450: "#909099",
  500: "#4f5352",
  600: "#363a39",
  700: "#272b2a",
  800: "#1b1f1e",
  900: "#121615",
};

// Primary Palette
export const primary = {
  50: "#3465E2",
  100: "#C4B8A5",
  200: "#FFFCF1",
  300: "#EDEAE6",
  400: "#FFFCF1",
  500: "#FFDA34",
  600: "#FFDA34",
  700: "#00878d",
  800: "#00686f",
  900: "#06565b",
};
// TODO: adjust colors
export const aptosColor = primary[600];
export const negativeColor: string = "#EA5330";
export const warningColor: string = "#EA5330";

// code block colors
export const codeBlockColor: string = "rgba(194,184,165,0.1)";
export const codeBlockColorClickableOnHover: string = "rgba(194,184,165,0.2)";
// use rgb for codeblock in modal otherwise it will be transparent and not very visible
export const codeBlockColorRgbLight: string = "#EDEAE6";
export const codeBlockColorRgbDark: string = "#000000";
